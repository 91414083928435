.r1w298v6.sx11gq7{position:fixed;left:0;top:var(--header-height);width:100%;z-index:90;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:0 18px;}@media (min-width:1280px){.r1w298v6.sx11gq7{padding:0 30px;}}.dark .r1w298v6.sx11gq7{background-color:#464646;}
.icltgzb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.narhkqj{font-size:12px;font-weight:normal;font-family:Univers;-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;}
.n15b18vp.narhkqj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.n15b18vp.narhkqj:first-child{margin-left:0px;}
.n1mfbgay{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:12px;font-weight:normal;font-family:Univers;-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;}
.nvhx8pa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.nvhx8pa:first-child{margin-right:20px;}
.n1jmyxdo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;cursor:pointer;}.n1jmyxdo.disabled{opacity:0.5;}
.n1duc29r{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:8px;}
.ve8twm4.narhkqj{margin-left:10px;margin-right:10px;}
.njhsqq0.sx11gq7{position:relative;}
.m1bhc4gd.s1noqf0t{font-size:8px;margin-left:5px;margin-right:5px;}
